<template>
  <div data-app>
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-body pt-3 pb-0">
        <div class="row">
          <div class="col-3">
            <v-autocomplete
              v-model="currentJobber"
              :items="jobbers"
              item-value="uniqueID"
              item-text="nombre"
              label="Jobber Actual"
              dense
            ></v-autocomplete>
          </div>
          <div class="col-3">
            <v-autocomplete
              v-model="newJobber"
              :items="jobbers"
              item-value="uniqueID"
              item-text="nombre"
              label="Nuevo Jobber"
              dense
            ></v-autocomplete>
          </div>
          <v-col cols="2">
            <v-autocomplete
              v-model="type"
              :items="types"
              item-value="id"
              item-text="name"
              label="Tipo"
              autocomplete="new-password"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="value"
              class="m-0 p-0"
              label="Valor"
              single-line
              hide-details
              @keyup.enter.native="addValue"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              @click="addValue()"
              color="primary"
              dark
              class="font-weight-bold text-uppercase px-9 py-4 float-right"
            >
              Agregar
            </v-btn>
          </v-col>
        </div>
      </div>
    </div>
    <v-row>
      <v-col cols="6">
        <div class="card card-custom card-stretch">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Cantidad: {{ values.length }}</h3>
            </div>
          </div>
          <div class="card-body p-5 pb-7">
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="values"
                  item-key="key"
                  disable-pagination
                  hide-default-footer
                  class="table-striped elevation-1"
                >
                  <template v-slot:[`item.actions`]="{ index }">
                    <v-icon small class="mr-2" @click="deleteValue(index)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12">
                <v-btn
                  @click="reassign()"
                  color="primary"
                  dark
                  class="font-weight-bold text-uppercase px-9 py-4 float-right"
                >
                  Reasignar
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { GET_JOBBERS, REASSIGN } from "@/core/services/store/jobber.module";
export default {
  name: "reassign",
  data() {
    return {
      jobbers: [],
      currentJobber: null,
      newJobber: null,
      type: null,
      value: "",
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      types: [
        {
          id: "tasks",
          name: "Tareas",
        },
        {
          id: "campaigns",
          name: "Campañas",
        },
      ],
      values: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Reasignar", route: "reassign" },
    ]);
  },
  created() {
    this.getJobbers();
  },
  methods: {
    getJobbers() {
      KTUtil.setLoading();
      this.$store.dispatch(GET_JOBBERS).then((jobbers) => {
        this.jobbers = jobbers;
        KTUtil.removeLoading();
      });
    },
    addValue() {
      let error = "";
      if (isNaN(this.value)) {
        error = "Ingrese un ID valido";
      }
      if (this.values.find((value) => value.id === this.value)) {
        error = "Este ID ya esta agregado a la tabla";
      }
      if (error) {
        Swal.fire({
          title: `Error al agregar`,
          text: error,
          icon: "error",
        });
        return;
      }
      this.values.push({
        id: this.value,
      });
      this.value = "";
    },
    deleteValue(index) {
      this.values.splice(index, 1);
    },
    validateForm() {
      let error = "";
      if (this.values.length === 0) {
        error = "Debe agregar valores a la tabla";
      }
      if (this.currentJobber === this.newJobber) {
        error =
          "Los jobbers seleccionados son iguales, seleccione uno distinto";
      }
      if (!this.type) {
        error = "Debe seleccionar un tipo";
      }
      if (!this.newJobber) {
        error = "Debe seleccionar el nuevo jobber";
      }
      if (!this.currentJobber) {
        error = "Debe seleccionar el jobber actual";
      }
      if (error) {
        Swal.fire({
          title: `Error al agregar`,
          text: error,
          icon: "error",
        });
        return false;
      }
      return true;
    },
    async reassign() {
      const validForm = this.validateForm();
      if (!validForm) return;

      const body = {
        currentJobberId: this.currentJobber,
        newJobberId: this.newJobber,
        type: this.type,
        values: this.values.map((value) => +value.id),
      };

      KTUtil.setLoading();
      const response = await this.$store.dispatch(REASSIGN, body);
      try {
        if (response) {
          Swal.fire({
            title: "¡Enhorabuena!",
            text:
              response?.message ||
              "Los registros fueron reasignados correctamente.",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          });
          this.values = [];
        } else {
          Swal.fire({
            title: "Error al reasignar",
            text: "Comuniquese con el administrador del sistema.",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        }
      } catch (e) {
        Swal.fire({
          title: "Error al reasignar",
          text: "Comuniquese con el administrador del sistema.",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
      }
      KTUtil.removeLoading();
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
};
</script>
